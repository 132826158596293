import React from 'react'

import {
  Blanket,
  BlanketBackground,
  BlanketContent,
} from '../../components/Blanket'
import { Container } from '../../components/Container'
import { SEO } from '../../components/Seo'

export default function BecomeSponsorPage() {
  return (
    <Blanket>
      <SEO title={'Diventa sponsor'} />

      <BlanketBackground>
        <BlanketContent>
          <Container>
            <iframe
              allowTransparency
              frameBorder={0}
              src='https://go.pallacanestroreggiana.it/l/1043883/2024-07-01/dgrw2y'
              width={'100%'}
              height={1200}
              style={{ border: 0 }}
              type={'text/html'}
            ></iframe>
          </Container>
        </BlanketContent>
      </BlanketBackground>
    </Blanket>
  )
}
